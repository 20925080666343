import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import * as gqlQueries from '@/graphql/customQueries';
import * as gqlMutations from '@/graphql/customMutations';

export const state = {
  nextToken: null,
  nextTokenListChat: null,
  nextTokenScrollDownListChat: null,
};

export const mutations = {
  set(state, { key, value }) {
    state[key] = value;
  },
  RESET_LISTCHAT_TOKEN(state) {
    state.nextTokenListChat = null;
    state.nextTokenScrollDownListChat = null;
  },
};

const publicDataValue = {
  getJoinSpaceKey: 'getJoinSpaceKey',
  getVideoGuest: 'getVideo',
  listCommentsGuest: 'listComments',
  listGuestComments: 'listGuestComments',
  createGuestComment: 'createGuestComment',
  updateGuestComment: 'updateGuestComment',
  deleteGuestComment: 'deleteGuestComment',
  listLikes: 'listLikes',
  getSpacePublic: 'getSpace',
  listPlanMasters: 'listPlanMasters',
  listProfilesPublic: 'listProfiles',
  listUserAccounts: 'listUserAccounts',
  updateVideoInfo: 'updateVideoInfo',
};

export const actions = {
  async get({ commit, dispatch }, { key, query, id }) {
    try {
      const { data } = await API.graphql({
        query: gqlQueries[query],
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      const value = data[query];
      if (key) commit('set', { key, value });
      return value;
    } catch (e) {
      if (e === 'No current user') {
        await dispatch('auth/logout', null, { root: true });
        this.app.router.push('/login');
        return;
      }
      throw e;
    }
  },

  async getPublic({ commit, dispatch }, { key, query, id }) {
    try {
      const { data } = await API.graphql({
        query: gqlQueries[query],
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      const value = data[publicDataValue[query]];
      if (key) commit('set', { key, value });
      return value;
    } catch (e) {
      if (e === 'No current user') {
        await dispatch('auth/logout', null, { root: true });
        this.app.router.push('/login');
        return;
      }
      throw e;
    }
  },

  async query(
    { commit, dispatch },
    {
      key,
      query,
      filter,
      limit,
      nextToken,
      isHighLightList = false,
      getNextToken = false,
      ...variables
    } // eslint-disable-line
  ) {
    try {
      if (!limit) {
        limit = 100000000;
      }
      const { data } = await API.graphql({
        query: gqlQueries[query],
        variables: { filter, limit, nextToken, ...variables },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const { nextToken: returnNextToken } = data[query];

      if (['spaceChatByCreatedAt', 'groupChatByCreatedAt'].includes(query)) {
        if (isHighLightList) {
          commit('set', { key: 'nextTokenScrollDownListChat', value: returnNextToken });
        } else {
          commit('set', { key: 'nextTokenListChat', value: returnNextToken });
        }
      } else {
        commit('set', { key: 'nextToken', value: returnNextToken });
      }

      const value = data[query].items;
      if (key) commit('set', { key, value });
      return getNextToken ? { items: value, nextToken: returnNextToken } : value;
    } catch (e) {
      if (e === 'No current user') {
        await dispatch('auth/logout', null, { root: true });
        this.app.router.push('/login');
        return;
      }
      throw e;
    }
  },

  async queryAll(
    { commit, dispatch },
    { key, query, filter, limit, ...variables } // eslint-disable-line
  ) {
    try {
      if (!limit) {
        limit = 100000000;
      }

      let data = null;
      let tries = 0;
      const value = [];

      do {
        const nextToken = data && data.nextToken;
        if (nextToken) {
          variables.nextToken = nextToken;
        }
        // eslint-disable-next-line no-await-in-loop
        const res = await API.graphql({
          query: gqlQueries[query],
          variables: { filter, limit, nextToken, ...variables },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        data = res.data[query];
        value.push(...data.items);
        tries += 1;
      } while (data.nextToken && tries < 5);
      if (key) commit('set', { key, value });
      return value;
    } catch (e) {
      if (e === 'No current user') {
        await dispatch('auth/logout', null, { root: true });
        this.app.router.push('/login');
        return;
      }
      throw e;
    }
  },

  async queryPublic({ commit, dispatch }, { key, query, filter, limit, nextToken }) {
    try {
      if (!limit) {
        limit = 100000000;
      }

      let data = null;
      let tries = 0;
      const value = [];
      do {
        if (data && data.nextToken) {
          nextToken = data.nextToken;
        }
        // eslint-disable-next-line no-await-in-loop
        const res = await API.graphql({
          query: gqlQueries[query],
          variables: { filter, limit, nextToken },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        data = res.data[publicDataValue[query]];
        value.push(...data.items);
        tries += 1;
        // const value = data[publicDataValue[query]].items;
      } while (data.nextToken && tries < 5);
      if (key) commit('set', { key, value });
      return value;
    } catch (e) {
      if (e === 'No current user') {
        await dispatch('auth/logout', null, { root: true });
        this.app.router.push('/login');
        return;
      }
      throw e;
    }
  },

  async mutate({ commit, dispatch }, { key, mutation, input }) {
    try {
      const { data } = await API.graphql({
        query: gqlMutations[mutation],
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
      const value = data[mutation];
      if (key) commit('set', { key, value });
      return value;
    } catch (e) {
      if (e === 'No current user') {
        await dispatch('auth/logout', null, { root: true });
        this.app.router.push('/login');
        return;
      }
      throw e;
    }
  },

  async mutatePublic({ commit, dispatch }, { key, mutation, input }) {
    try {
      const { data } = await API.graphql({
        query: gqlMutations[mutation],
        variables: { input },
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      const value = data[publicDataValue[mutation]];
      if (key) commit('set', { key, value });
      return value;
    } catch (e) {
      if (e === 'No current user') {
        await dispatch('auth/logout', null, { root: true });
        this.app.router.push('/login');
        return;
      }
      throw e;
    }
  },

  async resetListChatToken({ commit }) {
    commit('RESET_LISTCHAT_TOKEN');
  },
};

export const getters = {
  nextToken: (state) => state.nextToken,
  nextTokenListChat: (state) => state.nextTokenListChat,
  nextTokenScrollDownListChat: (state) => state.nextTokenScrollDownListChat,
};
